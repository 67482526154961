import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/shippingInstruction'

const shippingInstruction = axios.create({
  baseURL
})

interceptorsSetup(shippingInstruction)

export default class ShippingInstruction {
  static async runJob () {
    const { data: result } = await shippingInstruction.get('run-job')
    return result
  }
}
