import { render, staticRenderFns } from "./return-data.vue?vue&type=template&id=36fe194e&scoped=true&"
import script from "./return-data.vue?vue&type=script&lang=js&"
export * from "./return-data.vue?vue&type=script&lang=js&"
import style0 from "./return-data.vue?vue&type=style&index=0&id=36fe194e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36fe194e",
  null
  
)

export default component.exports