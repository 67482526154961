<template>
  <form @submit.prevent="$emit('submit', si)">
    <header class="modal-card-head">
      Edit
    </header>
    <section class="modal-card-body" style="height: 510px">
      <b-field
        label="USD Rate"
        label-position="on-border"
      >
        <b-input v-model="si.usdRate" />
      </b-field>
      <b-field
        label="Comment 1"
        label-position="on-border"
      >
        <b-input v-model="si.comment1" type="textarea" />
      </b-field>
      <b-field
        label="Comment 2"
        label-position="on-border"
      >
        <b-input v-model="si.comment2" type="textarea" />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <b-button
          icon-left="save"
          type="is-primary"
          native-type="submit"
        >
          Save
        </b-button>
      </div>
    </footer>
  </form>
</template>

<script>

export default {
  props: {
    si: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      isLoading: true
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
