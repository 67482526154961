<template>
  <div id="lateral-menu">
    <router-link to="/">
      <img class="image" src="@/assets/gps_logo.png" width="270px">
    </router-link>
    <hr class="is-marginless">
    <b-menu :activable="false">
      <b-menu-list :label="$t('sidebar.general')">
        <b-menu-item v-if="authorize('/maps')" tag="router-link" to="/maps">
          <template slot="label">
            <b-icon icon="map-marker-alt" type="is-danger" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.map') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/import-settings')" tag="router-link" to="/import-settings">
          <template slot="label">
            <b-icon icon="database" type="is-warning" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.edi') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/shipping-instructions')" tag="router-link" to="/shipping-instructions">
          <template slot="label">
            <b-icon icon="tree" type="is-primary" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.natural_rubber') }}</span>
          </template>
        </b-menu-item>
      </b-menu-list>
      <b-menu-list v-if="selectedPlant" :label="$t('sidebar.plant')">
        <b-menu-item v-if="authorize({ name: 'plant-production', params: {plant_id: selectedPlant } })" tag="router-link" :to="{ name: 'plant-production', params: {plant_id: selectedPlant } }">
          <template slot="label">
            <b-icon icon="industry" type="is-info" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.production') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize({ name: 'plant-loads', params: {plant_id: selectedPlant } })" tag="router-link" :to="{ name: 'plant-loads', params: {plant_id: selectedPlant } }">
          <template slot="label">
            <b-icon icon="truck-moving" type="is-dark" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.loads') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize({ name: 'plant-stock', params: {plant_id: selectedPlant } })" tag="router-link" :to="{ name: 'plant-stock', params: {plant_id: selectedPlant } }">
          <template slot="label">
            <b-icon icon="boxes" type="is-warning" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.stock') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize({ name: 'plant-scans', params: {plant_id: selectedPlant } })" tag="router-link" :to="{ name: 'plant-scans', params: {plant_id: selectedPlant } }">
          <template slot="label">
            <b-icon icon="barcode" type="is-danger" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.scans') }}</span>
          </template>
        </b-menu-item>

        <b-menu-item
          v-if="selectedPlant && ['C', 'D'].includes(selectedPlant.charAt(2))"
          tag="a"
          target="_blank"
          href="https://frgps.sharepoint.com/sites/Subcontractor"
        >
          <template slot="label">
            <b-icon icon="external-link-alt" type="is-purple" size="is-small" />
            <template>Sharepoint</template>
          </template>
        </b-menu-item>
      </b-menu-list>
      <b-menu-list :label="$t('sidebar.administration')">
        <b-menu-item v-if="authorize('/dashboard/:id')">
          <template slot="label" slot-scope="props">
            <b-icon icon="chart-bar" type="is-blue" size="is-small" />
            <span class="has-text-grey">Dashboard</span>
            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-down' : 'caret-up'" />
          </template>

          <b-menu-item v-for="(item, index) in allDashboardsName" :key="index" tag="router-link" :to="{ name: 'dashboard', params: { id: index }}">
            <template slot="label">
              <span class="has-text-grey">{{ item.name.replaceAll("_", " ") }}</span>
            </template>
          </b-menu-item>
        </b-menu-item>

        <b-menu-item v-if="authorize('/plants')" tag="router-link" to="/plants" exact>
          <template slot="label">
            <b-icon icon="warehouse" type="is-dark" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.plants') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/customers')" tag="router-link" to="/customers">
          <template slot="label">
            <b-icon icon="address-card" type="is-info" pack="far" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.customers') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/devices')" tag="router-link" to="/devices">
          <template slot="label">
            <b-icon icon="mobile-alt" type="is-grey" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.devices') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item>
          <template slot="label" slot-scope="props">
            <b-icon icon="id-card" type="is-purple" pack="far" size="is-small" />
            <span class="has-text-grey">Identity</span>
            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-down' : 'caret-up'" />
          </template>
          <b-menu-item v-if="authorize('/users')" tag="router-link" to="/users">
            <template slot="label">
              <b-icon icon="user" type="is-info" pack="far" size="is-small" />
              <span class="has-text-grey">{{ $t('sidebar.users') }}</span>
            </template>
          </b-menu-item>
          <b-menu-item v-if="authorize('/groups')" tag="router-link" to="/groups">
            <template slot="label">
              <b-icon icon="users" type="is-primary" size="is-small" />
              <span class="has-text-grey">{{ $t('sidebar.groups') }}</span>
            </template>
          </b-menu-item>
          <b-menu-item v-if="authorize('/roles')" tag="router-link" to="/roles">
            <template slot="label">
              <b-icon icon="user-shield" type="is-warning" size="is-small" />
              <span class="has-text-grey">{{ $t('sidebar.roles') }}</span>
            </template>
          </b-menu-item>
        </b-menu-item>
        <b-menu-item v-if="authorize('/upload')" tag="router-link" to="/upload">
          <template slot="label">
            <b-icon icon="upload" type="is-success" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.upload') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/sap')" tag="router-link" to="/sap">
          <template slot="label">
            <b-icon icon="wrench" type="is-danger" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.sap') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/enduser-declaration-admin')" tag="router-link" to="/enduser-declaration-admin">
          <template slot="label">
            <b-icon icon="scroll" type="is-link" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.pickup_management') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/enduser-declaration-admin')" tag="router-link" to="/closure-schedule">
          <template slot="label">
            <b-icon icon="door-closed" type="is-warning" size="is-small" />
            <span class="has-text-grey">{{ $t('pages.closure_schedule.sidebar_link') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/distribution')" tag="router-link" to="/distribution">
          <template slot="label">
            <b-icon icon="diagram-project" type="is-link" size="is-small" />
            <span class="has-text-grey">Distribution</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/crate-peremption')" tag="router-link" to="/crate-peremption">
          <template slot="label">
            <b-icon icon="hourglass-end" type="is-dark" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.barcode_reminder') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/report')" tag="router-link" to="/report">
          <template slot="label">
            <b-icon icon="file-alt" type="is-primary" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.report') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/customer-forecast')" tag="router-link" to="/customer-forecast">
          <template slot="label">
            <b-icon icon="chart-area" type="is-black" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.customer_forecast') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/new-crates')" tag="router-link" to="/new-crates">
          <template slot="label">
            <b-icon icon="cube" type="is-yellow" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.new_crates') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/admin-panel')" tag="router-link" to="/admin-panel">
          <template slot="label">
            <b-icon icon="users-cog" type="is-yellow" size="is-small" />
            <span class="has-text-grey">{{ $t('sidebar.admin_panel') }}</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Powerbi from '@/services/v2/powerbi'

export default {
  data () {
    return {
      allDashboardsName: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    selectedPlant: {
      get () {
        return this.$store.state.plantModule.selectedPlant
      }
    }
  },
  mounted: function () {
    if (this.user && this.user.departments && this.user.departments[0]) {
      this.$store.dispatch('setPlant', this.user.departments[0].id)
    }
    const rights = this.user?.role?.rights?.map(e => e.name)
    if (rights.find(el => el === 'Dashboard access') === 'Dashboard access') {
      this.getPowerBI()
    }
  },
  methods: {
    authorize: function (to) {
      const found = this.$router.resolve(to)
      if (!found) return false
      const { authorize } = found.route.meta
      if (authorize) {
        if (!this.user) return false
        const rights = this.user?.role?.rights?.map(e => e.name)

        return authorize.length && authorize.some(r => rights.includes(r))
      }
      return true
    },
    getPowerBI: async function () {
      const res = await Powerbi.getPowerBIReports()
      const tmp = res.value
      for (let i = 0; this.$store.state.auth.user.group.powerBis.length !== i; i++) {
        for (let it = 0; tmp.length !== it; it++) {
          if (this.$store.state.auth.user.group.powerBis[i].id === tmp[it].id) {
            this.allDashboardsName.push(tmp[it])
          }
        }
      }
    }
  }

}
</script>
<style>
#lateral-menu li a.is-active span{
  color: white !important;
}
</style>
