import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_V3 + '/enduserClosureMail'

const enduserClosureMail = axios.create({
  baseURL
})

interceptorsSetup(enduserClosureMail)

export default class EnduserClosureMail {
  static async getAll () {
    const result = await enduserClosureMail.get('/all')
    return result.data
  }

  static async get (collectGroup) {
    const result = await enduserClosureMail.get('/' + collectGroup)
    return result.data
  }

  static async getSchedule (collectGroup) {
    const result = await enduserClosureMail.get('/schedule/' + collectGroup)
    return result.data
  }

  static async updateSchedule (schedule) {
    const result = await enduserClosureMail.post('/schedule', schedule)
    return result.data
  }

  static async sendMail (user) {
    const result = await enduserClosureMail.post('/sendMail', user)
    return result.data
  }

  static async sendMails () {
    const result = await enduserClosureMail.post('/sendMails')
    return result.data
  }

  static async getEdit (templateId) {
    const result = await enduserClosureMail.get('/edit/' + templateId)
    return result.data
  }

  static async insert (data) {
    const result = await enduserClosureMail.post('/', data)
    return result.data
  }

  static async update (data) {
    const result = await enduserClosureMail.post('/edit/' + data.id, data)
    return result.data
  }

  static async delete (id) {
    const result = await enduserClosureMail.delete(`/${id}`)
    return result.data
  }
}
