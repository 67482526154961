<template>
  <section id="shipping-instructions" class="section">
    <div class="flex is-flex-direction-row is-justify-content-flex-end mb-3">
      <div class="buttons">
        <b-button @click="HandleMultipleCreation">
          Create PO
        </b-button>
        <b-button
          type="is-info"
          @click="onRuningJob"
        >
          Run job
        </b-button>
      </div>
    </div>

    <ag-grid-vue
      style="width: 100%; height: 700px;"
      class="ag-theme-alpine"
      :row-data="formattedShippingInstructions"
      :grid-options="gridOptions"
      :get-context-menu-items="getContextMenuItems"
      :pagination="true"
      :animate-rows="true"
      :pagination-auto-page-size="true"
      :side-bar="['columns']"
      :multi-sort-key="'ctrl'"
      :modules="modules"
      @grid-ready="gridReady"
    />

    <b-modal scroll="keep" has-modal-card :active.sync="creating.isOpened">
      <div class="modal-card">
        <SiForm
          :si="editingSi"
          @submit="editShippingInstruction"
        />
      </div>
    </b-modal>

    <b-modal scroll="keep" :active.sync="isDetailView">
      <DetailModal
        :si="selectedSiNumber"
      />
    </b-modal>
  </section>
</template>

<script>
import { columns } from './columns.js'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import SiForm from './form'
import DetailModal from './detailModal.vue'

import {
  ShippingInstructionDelete,
  ShippingInstructionCreateOrder,
  ShippingInstructionEdit,
  ShippingInstructionUpdateDB
} from '@/graphql/shippingInstruction'

import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'
import shippingInstruction from '@/services/v2/shippingInstruction'

export default {
  components: {
    SiForm,
    DetailModal
  },
  mixins: [sortAndFilterMixins],
  data: () => {
    return {
      selectedSiNumber: '',
      isDetailView: false,
      modules: AllModules,
      gridOptions: {
        cacheBlockSize: 12,
        rowModelType: 'serverSide',
        serverSideStoreType: 'partial',
        getRowNodeId: params => params.id,
        isRowSelectable: rowNode => {
          return rowNode.data && rowNode.data.status === 'U' && !rowNode.data.purchaseOrderNumber && rowNode.data.processorId
        },
        rowSelection: 'multiple',
        paginationPageSize: 12,
        enableBrowserTooltips: true,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: false,
          enablePivot: false,
          sortable: true,
          filter: true,
          resizable: true
        },
        autoGroupColumnDef: {
          sort: 'desc'
        },
        columnDefs: [ {
          headerName: '',
          checkboxSelection: true,
          width: 50
        }, ...columns]
      },
      creating: {
        isLoading: false,
        isOpened: false,
        selectedSI: null
      },
      shippingInstructions: [],
      editingSi: {}
    }
  },

  computed: {
    formattedShippingInstructions () {
      return this.shippingInstructions.map(item => {
        return {
          formattedEtd: item.feeder_etd
            ? this.$dayjs(item.feeder_etd).format('DD/MM/YYYY')
            : null,
          formattedSiDate: this.$dayjs(item.si_date).format('DD/MM/YYYY'),
          historyDateFormatted: item.history_created
            ? this.$dayjs(item.history_created).format('DD/MM/YYYY')
            : null,
          showDate: item.history_created
            ? this.$dayjs(item.history_created).format('DD/MM/YYYY')
            : this.$dayjs(item.si_date).format('DD/MM/YYYY'),
          ...item
        }
      })
    }
  },
  async mounted () {
    await this.$apollo.mutate({
      mutation: ShippingInstructionUpdateDB
    })
  },
  methods: {
    gridReady: async function (params) {
      const dataSource = {
        getRows: async getRowParams => {
          const allcolumns = this.gridOptions.columnDefs.slice(1).map(colDef => colDef.field)

          const builder = this.$gql.query({
            operation: 'shippingInstruction',
            fields: [
              'totalCount',
              {
                edges: [
                  {
                    node: [...allcolumns]
                  }
                ]
              }
            ],
            variables: {
              skip: { type: 'Int', value: getRowParams.request.startRow },
              limit: { type: 'Int', value: getRowParams.request.endRow },
              sort: {
                type: '[sortInputshippingInstruction]',
                value: this.getSorts(getRowParams.request)
              },
              filter: {
                type: 'filterInputshippingInstruction',
                value: this.getFilters(getRowParams.request)
              },
              group: {
                type: 'Boolean',
                value: false
              }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`
              ${builder.query}
            `,
            variables: builder.variables
          })

          const shippingInstructionsResult = result.data.shippingInstruction

          this.totalCount = shippingInstructionsResult.totalCount
          this.shippingInstructions = shippingInstructionsResult.edges.map(
            e => e.node
          )

          getRowParams.successCallback(
            this.shippingInstructions,
            this.totalCount
          )
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getContextMenuItems (params) {
      const selected = params?.node?.data
      const customMenu = []

      const genericMenu = ['copy', 'copyWithHeaders', 'separator', 'export']

      if (selected) {
        customMenu.push(
          {
            name: 'Details',
            icon: '<i class="fas fa-fw fa-eye" ></i>',
            action: () => {
              this.selectedSiNumber = selected.ref
              this.isDetailView = true
            }
          },
          {
            name: 'Edit',
            action: () => {
              this.edit(selected)
            },
            icon: '<i class="fas fa-fw fa-edit" ></i>'
          }
        )
        if (selected.status !== 'C' && selected.status !== 'V') {
          customMenu.push(
            // 'separator',
            {
              name: 'Create order',
              action: async () => {
                await this.createOrder(selected)
              },
              icon: '<i class="fas fa-fw fa-check" ></i>'
            }
          )
        }
      }

      return [...customMenu, ...genericMenu]
    },
    async editShippingInstruction (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      await this.$apollo.mutate({
        mutation: ShippingInstructionEdit,
        variables: {
          id: shippingInstruction.id,
          usdRate: shippingInstruction.usdRate,
          comment1: shippingInstruction.comment1,
          comment2: shippingInstruction.comment2
        }
      })
      this.gridOptions.api.refreshServerSideStore()
      this.gridOptions.api.hideOverlay()
      this.creating.isOpened = false
    },
    async createOrder (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      this.$apollo
        .mutate({
          mutation: ShippingInstructionCreateOrder,
          variables: {
            id: shippingInstruction.id,
            departurePlantId: shippingInstruction.departurePlantId
          }
        })
        .then(res => {
          if (res.data.shippingInstructionCreateOrder === true) {
            this.$buefy.notification.open({
              type: 'is-success',
              message: 'Order created successfully',
              duration: 2500,
              hasIcon: true
            })
            this.gridOptions.api.refreshServerSideStore()
          }
        })
      this.gridOptions.api.hideOverlay()
    },
    async delete (shippingInstruction) {
      this.gridOptions.api.showLoadingOverlay()
      this.$apollo.mutate({
        mutation: ShippingInstructionDelete,
        variables: {
          id: shippingInstruction.id
        }
      })
      this.gridOptions.api.hideOverlay()
    },
    async edit (shippingInstruction) {
      this.editingSi = shippingInstruction
      this.creating.isOpened = true
      this.creating.shippingInstruction = shippingInstruction
    },
    async onRuningJob () {
      await shippingInstruction.runJob()
    },
    async HandleMultipleCreation () {
      this.gridOptions.api.showLoadingOverlay()
      const selectedRows = this.gridOptions.api.getSelectedRows()
      if (!selectedRows.length) return

      try {
        await Promise.all(
          selectedRows.map(row => {
            return this.$apollo
              .mutate({
                mutation: ShippingInstructionCreateOrder,
                variables: {
                  id: row.id,
                  departurePlantId: row.departurePlantId
                }
              })
          })
        )
        this.gridOptions.api.deselectAll()
        this.gridOptions.api.refreshServerSideStore()
        this.$buefy.notification.open({
          type: 'is-success',
          message: 'Orders created successfully',
          duration: 2500,
          hasIcon: true
        })
        this.gridOptions.api.hideOverlay()
      } catch (e) {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: 'Failed to create POs',
          duration: 2500,
          hasIcon: true
        })
      }
    }
  }
}
</script>
