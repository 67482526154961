import UploadPage from '@/views/GpsViews/Admin/Upload/upload-page'
import GroupPage from '@/views/GpsViews/Admin/Groups/group-page'
import UserPage from '@/views/GpsViews/Admin/Users/user-page'
import RolesPage from '@/views/GpsViews/Admin/Roles/role-page'
import EnduserDeclarationAdmin from '@/views/EnduserDeclarationAdmin/screenSwitcher.vue'
import EnduserDeclarationAdminLayout from '@/views/EnduserDeclarationAdmin/layout.vue'
import DistributionPage from '@/views/Distribution/distribution-page'
import ReportLayout from '@/views/GpsViews/Admin/Report/layout.vue'
import ReportPage from '@/views/GpsViews/Admin/Report/report.vue'
import ReportTranslationPage from '@/views/GpsViews/Admin/Report/reportTranslation.vue'
import CratePeremption from '@/views/cratePeremption/index.vue'
import closureTemplates from '@/views/EnduserDeclarationAdmin/closureTemplates.vue'
import closureTemplatesForm from '@/views/EnduserDeclarationAdmin/closureTemplatesForm.vue'

import DevicePage from '@/views/GpsViews/Admin/Devices/device-page'
import SapPage from '@/views/GpsViews/Admin/SAP/'

import CustomersLayout from '@/views/GpsViews/Admin/Customers/layout'
import CustomersPage from '@/views/GpsViews/Admin/Customers/'
import CustomerPartnersPage from '@/views/GpsViews/Admin/Customers/Endusers'
import CustomerStockPage from '@/views/GpsViews/Admin/Customers/Stock'
import CustomersDynamicFieldsPage from '@/views/GpsViews/Admin/Customers/dynamicFields'

import PlantsPage from '@/views/GpsViews/Admin/Plants/'
import PlantLayout from '@/views/GpsViews/Admin/Plants/layout'
import PlantStockPage from '@/views/GpsViews/Admin/Plants/Plant/Stock/'
import SimulationPage from '@/views/GpsViews/Admin/Plants/Plant/Simulation/'
import PlantChartsPage from '@/views/GpsViews/Admin/Plants/Plant/Production/'
import PlantLoadsPage from '@/views/GpsViews/Admin/Plants/Plant/Loads/'
import FreeScanPage from '@/views/GpsViews/Admin/Plants/Plant/Free/'
import PlantProductionCheckup from '@/views/GpsViews/Admin/Plants/Plant/Production/checkup/index'
import PlantProductionForecast from '@/views/GpsViews/Admin/Plants/Plant/Production/forecast'
import PlantProducerStock from '@/views/GpsViews/Admin/Plants/Producer/Stock'
import EdiPage from '@/views/GpsViews/Admin/EDI/edi-page'
import EditFileSettingsPage from '@/views/GpsViews/Admin/EDI/edit-file-settings'
import ShippingInstructionsPage from '@/views/GpsViews/Admin/ShippingInstructions/index'
import ExportRecapAllPage from '@/views/GpsViews/Admin/EDI/export-recap-all'
import ExportDetailsPage from '@/views/GpsViews/Admin/EDI/export-details'
import CustomerForecast from '@/views/GpsViews/Admin/customerForecast'
import NewCrates from '@/views/GpsViews/Admin/NewCrates'
import AdminPanel from '@/views/GpsViews/Admin/AdminPanel/adminPanel.vue'

import DashboardPage from '@/views/GpsViews/Admin/Dashboards'
import i18n from '@/i18n'
import ClosureSchedule from '../views/EnduserDeclarationAdmin/closureSchedule.vue'

export default [
  {
    path: '/upload',
    component: UploadPage,
    name: 'upload',
    meta: { title: i18n.tc('sidebar.upload'), authorize: ['Upload'] }
  },
  {
    path: '/groups',
    component: GroupPage,
    name: 'groups',
    meta: { title: i18n.tc('sidebar.groups'), authorize: ['Groups'] }
  },
  {
    path: '/users',
    component: UserPage,
    name: 'users',
    meta: { title: i18n.tc('sidebar.users'), authorize: ['Users'] }
  },
  {
    path: '/roles',
    component: RolesPage,
    name: 'roles',
    meta: { title: i18n.tc('sidebar.roles'), authorize: ['Roles'] }
  },
  {
    path: '/closure-templates/:collect_group',
    component: closureTemplates,
    name: 'closure-templates',
    meta: { title: 'Closure Templates', authorize: ['Pickup management'] }
  },
  {
    path: '/closure-templates-form/:template_id/:collect_group',
    component: closureTemplatesForm,
    name: 'closure-templates-form',
    meta: { title: 'Closure Templates Form', authorize: ['Pickup management'] }
  },
  {
    path: '/closure-schedule/:collect_group?',
    component: ClosureSchedule,
    name: 'closure-schedule',
    meta: { title: 'Closure Schedule', authorize: ['Pickup management'] }
  },
  {
    path: '/enduser-declaration-admin',
    component: EnduserDeclarationAdminLayout,
    children: [
      {
        path: '/',
        name: 'enduserDeclarationAdmin',
        meta: { title: 'Enduser declarations', authorize: ['Pickup management'] },
        component: EnduserDeclarationAdmin
      }
    ]
  },
  {
    path: '/distribution',
    component: DistributionPage,
    name: 'distribution',
    meta: { title: 'Distribution', authorize: ['Distribution'] }
  },
  {
    path: '/report',
    component: ReportLayout,
    children: [
      {
        path: '/',
        name: 'report',
        component: ReportPage,
        meta: { title: 'Report', authorize: ['Report'] }
      },
      {
        path: 'translation',
        name: 'reportTranslation',
        component: ReportTranslationPage,
        meta: { title: 'Report translation', authorize: ['Report'] }
      }
    ]
  },
  {
    path: '/crate-peremption',
    component: CratePeremption,
    children: [
      {
        path: '/',
        name: 'cratePeremption',
        meta: { title: 'Crate peremption', authorize: ['Barcode reminder'] },
        component: CratePeremption
      }
    ]
  },
  {
    path: '/customers',
    component: CustomersLayout,
    children: [
      {
        path: '/',
        component: CustomersPage,
        name: 'customers',
        meta: {
          title: i18n.tc('sidebar.customers'),
          authorize: ['Customers']
        }
      },
      {
        path: ':customer_id/dynamicfields',
        component: CustomersDynamicFieldsPage,
        name: 'DynamicFields',
        meta: {
          title: 'Dynamic Fields',
          authorize: ['Customers']
        }
      },
      {
        path: ':customer_id/endusers',
        component: CustomerPartnersPage,
        name: 'customer-endusers',
        meta: {
          authorize: ['Endusers'],
          title: i18n.tc('sidebar.endusers'),
          breadcrumb: [
            'customers',
            'customer-endusers'
          ] }
      },
      {
        path: ':customer_id/stock',
        component: CustomerStockPage,
        name: 'customer-stock',
        meta: {
          authorize: ['Stocks'],
          title: i18n.tc('sidebar.stock'),
          breadcrumb: [
            'customers',
            'customer-stock'
          ] }
      }
    ]
  },

  {
    path: '/sap',
    component: SapPage,
    name: 'sap',
    meta: { title: 'Sap', authorize: ['SAP'] }
  },
  {
    path: '/devices',
    component: DevicePage,
    name: 'devices',
    meta: { title: 'Devices', authorize: ['Devices'] }
  },
  {
    path: '/dashboard/:id',
    props: true,
    params: { id: 0 },
    component: DashboardPage,
    name: 'dashboard',
    meta: { title: 'Dashboard', authorize: ['Dashboard access'] }
  },
  {
    path: '/plants',
    component: PlantLayout,
    children: [
      {
        path: '/',
        component: PlantsPage,
        name: 'plants',
        meta: {
          title: i18n.tc('sidebar.plants'),
          authorize: ['Plants']
        }
      },
      {
        path: 'simulation',
        component: SimulationPage,
        name: 'simulation',
        meta: {
          title: 'Simulation',
          authorize: ['Plants'],
          breadcrumb: [
            'plants',
            'simulation'
          ]
        }
      },
      {
        path: ':plant_id/scans',
        component: FreeScanPage,
        name: 'plant-scans',
        meta: {
          authorize: ['Scans'],
          title: i18n.tc('sidebar.scans'),
          breadcrumb: [
            'plants',
            'plant-scans'
          ] }
      },
      {
        path: ':plant_id/production',
        component: PlantChartsPage,
        name: 'plant-production',
        meta: {
          authorize: ['Production'],
          title: i18n.tc('sidebar.production'),
          breadcrumb: [
            'plants',
            'plant-production'
          ] }
      },
      {
        path: ':plant_id/forecast',
        component: PlantProductionForecast,
        name: 'plant-production-forecast',
        meta: {
          authorize: ['Production'],
          title: i18n.tc('sidebar.production-forecast'),
          breadcrumb: [
            'plants',
            'plant-production-forecast'
          ] }
      },
      {
        path: ':plant_id/production/:productionDate/checkup',
        component: PlantProductionCheckup,
        name: 'plant-production-checkup',
        meta: {
          authorize: ['Production'],
          title: i18n.tc('sidebar.production-checkup'),
          breadcrumb: [
            'plants',
            'plant-production',
            'plant-production-checkup'
          ] }
      },
      {
        path: ':plant_id/loads',
        component: PlantLoadsPage,
        name: 'plant-loads',
        meta: {
          authorize: ['Loads'],
          title: i18n.tc('sidebar.loads'),
          breadcrumb: [
            'plants',
            'plant-loads'
          ] }
      },
      {
        path: ':plant_id/stock',
        component: PlantStockPage,
        name: 'plant-stock',
        meta: {
          authorize: ['Stocks'],
          title: 'Stock',
          breadcrumb: [
            'plants',
            'plant-stock'
          ] }
      },
      {
        path: ':plant_id/producer-stock',
        component: PlantProducerStock,
        name: 'producer-stock',
        meta: {
          authorize: ['Stocks'],
          title: 'Producer stocks',
          breadcrumb: [
            'plants',
            'producer-stock'
          ]
        }
      }
    ]
  },
  {
    path: '/import-settings/:selectedCustomerId/edit-file-settings/:fileSettingsId',
    component: EditFileSettingsPage,
    name: 'edit-file-settings',
    meta: { title: i18n.tc('sidebar.edi'), authorize: ['EDI'] },
    props: true
  },
  {
    path: '/import-settings',
    component: EdiPage,
    name: 'edi',
    meta: { title: i18n.tc('sidebar.edi'), authorize: ['EDI'] },
    props: true
  },
  {
    path: '/shipping-instructions',
    component: ShippingInstructionsPage,
    name: 'shipping-instructions',
    meta: { title: 'Shipping Instructions', authorize: ['EDI'] }
  },
  {
    path: '/import-settings/:fip_id/history',
    component: ExportRecapAllPage,
    name: 'export-recap',
    meta: { title: 'Export recap', authorize: ['EDI'] },
    props: true
  },
  {
    path: '/import-history',
    component: ExportRecapAllPage,
    name: 'export-recap-all',
    meta: { title: 'Export recaps', authorize: ['EDI'] },
    props: true
  },
  {
    path: '/import-settings/:fip_id/history/:fih_id/data',
    component: ExportDetailsPage,
    name: 'export-details',
    meta: { title: 'Export details', authorize: ['EDI'] },
    props: true
  },
  {
    path: '/customer-forecast',
    component: CustomerForecast,
    name: 'customer-forecast',
    meta: { title: 'Customer forecast', authorize: ['Customer forecast'] },
    props: true
  },
  {
    path: '/new-crates',
    component: NewCrates,
    name: 'new-crates',
    meta: { title: 'New Crates', authorize: ['New Crates'] }
  },
  {
    path: '/admin-panel',
    component: AdminPanel,
    name: 'admin-panel',
    meta: { title: 'Admin panel', authorize: ['Admin Panel'] }
  }
]
