<template>
  <div class="section">
    <div class="container">
      <div class="level">
        <Header class="level-left" v-bind="$route.meta" />
        <b-button
          tag="router-link"
          :to="{ name: 'closure-schedule', params: { collect_group: this.$route.params.collect_group } }"
          class="left-right"
          type="is-info"
          icon-left="arrow-left"
        >
          {{ $t('global.back') }}
        </b-button>
        <b-button
          tag="router-link"
          :to="{ name: 'closure-templates-form', params: { template_id: 'new', collect_group: this.$route.params.collect_group } }"
          class="left-right"
          type="is-success"
          icon-left="plus"
        >
          {{ $t('pages.closure_schedule.mail_templates.new_button') }}
        </b-button>
      </div>
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-balham"
            :row-data="enduserMailTemplates"
            :grid-options="gridOptions"
            :pagination="true"
            :pagination-auto-page-size="true"
            :side-bar="['columns']"
            :cache-overflow-size="0"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            :get-context-menu-items="getContextMenuItems"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AllModules } from '@ag-grid-enterprise/all-modules'
// import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import enduserClosureMail from '@/services/v3/enduserClosureMail'

export default {
  name: 'ClosureTemplates',
  data: function () {
    return {
      modules: AllModules,
      enduserMailTemplates: [],
      gridOptions: {
        cacheBlockSize: 20,
        paginationPageSize: 20,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('pages.closure_schedule.mail_templates.name_column'),
            field: 'name',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            sort: 'asc',
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_templates.subject_column'),
            field: 'subject',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_templates.sender_column'),
            field: 'sender',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_templates.content_column'),
            field: 'content',
            filter: 'agTextColumnFilter',
            width: 260,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_templates.signature_column'),
            field: 'signature',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.closure_schedule.mail_templates.id_column'),
            field: 'id',
            filter: 'agTextColumnFilter',
            width: 260,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          }
        ]
      }
    }
  },
  mounted: async function () {
    try {
      enduserClosureMail.get(this.$route.params.collect_group).then((value) => {
        this.enduserMailTemplates = []
        value.forEach(element => {
          this.enduserMailTemplates.push(
            {
              id: element.id,
              name: element.templateName,
              content: element.html,
              subject: element.subject,
              sender: element.sender,
              collect: element.collectGroup,
              signature: element.signature
            }
          )
        })
      })
    } catch (error) {
      this.$buefy.toast.open({ message: error.message, duration: 3000, type: 'is-danger' })
    }
  },
  methods: {
    gridReady: function (params) {
    },
    getContextMenuItems (params) {
      const selectedTemplate = params.node.data
      let customMenu = []
      const genericMenu = [
        'separator',
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      customMenu.push({
        name: this.$t('pages.closure_schedule.context_menu.edit'),
        action: () => {
          this.$router.push({ name: 'closure-templates-form', params: { template_id: selectedTemplate.id, collect_group: this.$route.params.collect_group } })
        },
        icon: '<i class="fas fa-fw fa-edit" ></i>'
      })
      const result = [...customMenu, ...genericMenu]
      return result
    }
  }
}

</script>
